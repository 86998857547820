import { Box, Container, Image, Link as ChakraLink, SimpleGrid, Stack, Text, HStack, Flex } from '@chakra-ui/react';
import { FaDiscord, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { useRouter } from 'next/router';
import { Link } from '@chakra-ui/next-js';

interface FooterProps {
  isFeedbackPage?: boolean;
  isAnalyticsPage?: boolean;
  isBugReportPage?: boolean;
}

const Logo = ({ 
  isFeedbackPage = false, 
  isAnalyticsPage = false, 
  isBugReportPage = false 
}: { 
  isFeedbackPage?: boolean;
  isAnalyticsPage?: boolean;
  isBugReportPage?: boolean;
}) => {
  const isExternalPage = isFeedbackPage || isAnalyticsPage || isBugReportPage;
  const baseUrl = isExternalPage ? 'https://cohezion.ai' : '';

  return (
    <Link href={`${baseUrl}/`}>
      <Image 
        alt='COHEZION logo' 
        src='https://cohezion.ai/images/COHEZION_LOGOS-03.png' 
        width={'150px'} 
      />
    </Link>
  );
};

const SocialLink = ({ icon: Icon, href }: { icon: any, href: string }) => {
  return (
    <ChakraLink
      href={href}
      target="_blank"
      color="white"
      _hover={{ color: 'whiteAlpha.700' }}
    >
      <Icon size={24} />
    </ChakraLink>
  );
};

interface FooterLinkProps {
  label: string;
  href: string;
  section?: string;
  isFeedbackPage?: boolean;
  isAnalyticsPage?: boolean;
  isBugReportPage?: boolean;
}

const FooterLink = ({ 
  label, 
  href, 
  section, 
  isFeedbackPage = false,
  isAnalyticsPage = false,
  isBugReportPage = false
}: { 
  label: string; 
  href: string; 
  section?: string;
  isFeedbackPage?: boolean;
  isAnalyticsPage?: boolean;
  isBugReportPage?: boolean;
}) => {
  const router = useRouter();
  const isExternalPage = isFeedbackPage || isAnalyticsPage || isBugReportPage;
  const baseUrl = isExternalPage ? 'https://cohezion.ai' : '';
  const isHomePage = router.pathname === '/';

  const handleClick = (e: React.MouseEvent) => {
    if (section && isHomePage && !isExternalPage) {
      e.preventDefault();
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (section || isFeedbackPage) {
      e.preventDefault();
      window.location.href = `${baseUrl}${href}`;
    }
  };

  return (
    <Link
      href={isFeedbackPage ? `${baseUrl}${href}` : href}
      color="whiteAlpha.800"
      _hover={{ color: 'white' }}
      onClick={handleClick}
    >
      {label}
    </Link>
  );
};

export default function Footer({ isFeedbackPage = false, isAnalyticsPage = false, isBugReportPage = false }: FooterProps) {
  const productLinks = [
    { label: 'How it Works', href: '/#timesteps', section: 'timesteps' },
    { label: 'Bug Reports', href: '/bugreport' },
    { label: 'Feedback', href: '/feedback' },
    { label: 'Analytics', href: '/analytics' },
  ];

  const companyLinks = [
    { label: 'About', href: '/#about', section: 'about' },
    { label: 'Contact', href: '/#contact', section: 'contact' },
    { label: 'Privacy', href: '/privacy' },
    { label: 'Terms', href: '/terms' },
  ];

  const isExternalPage = isFeedbackPage || isAnalyticsPage || isBugReportPage;
  const baseUrl = isExternalPage ? 'https://cohezion.ai' : '';

  return (
    <Box bg="black" color="white" py={16}>
      <Container maxW="container.xl">
        <Flex 
          direction={{ base: 'column', lg: 'row' }}
          justify="space-between"
          align={{ base: 'flex-start', lg: 'flex-start' }}
        >
          {/* Left Column - Logo and Social */}
          <Stack spacing={6} mb={{ base: 12, lg: 0 }}>
            <Logo 
              isFeedbackPage={isFeedbackPage}
              isAnalyticsPage={isAnalyticsPage}
              isBugReportPage={isBugReportPage}
            />
            <HStack spacing={6}>
              <SocialLink 
                icon={FaXTwitter} 
                href="https://www.twitter.com/cohezion_ai" 
              />
              <SocialLink 
                icon={FaLinkedin} 
                href="https://www.linkedin.com/company/91417480" 
              />
              <SocialLink 
                icon={FaDiscord} 
                href="https://discord.gg/j5XvZJJAsF" 
              />
            </HStack>
            <Text color="whiteAlpha.800" fontSize="sm">
              © 2024 COHEZION.ai All rights reserved
            </Text>
          </Stack>

          {/* Right Side - Link Columns */}
          <SimpleGrid 
            columns={{ base: 1, sm: 2 }} 
            spacing={{ base: 8, sm: 16, lg: 24 }}
            flex="1"
            maxW={{ lg: '500px' }}
          >
            <Stack spacing={4}>
              <Text fontWeight="bold" fontSize="lg">Product</Text>
              {productLinks.map((link) => (
                <FooterLink 
                  key={link.label}
                  label={link.label}
                  href={link.href}
                  section={link.section}
                  isFeedbackPage={isFeedbackPage}
                  isAnalyticsPage={isAnalyticsPage}
                  isBugReportPage={isBugReportPage}
                />
              ))}
            </Stack>

            <Stack spacing={4}>
              <Text fontWeight="bold" fontSize="lg">Company</Text>
              {companyLinks.map((link) => (
                <FooterLink 
                  key={link.label}
                  label={link.label}
                  href={link.href}
                  section={link.section}
                  isFeedbackPage={isFeedbackPage}
                  isAnalyticsPage={isAnalyticsPage}
                  isBugReportPage={isBugReportPage}
                />
              ))}
            </Stack>
          </SimpleGrid>
        </Flex>
      </Container>
    </Box>
  );
}
